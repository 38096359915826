import layouts from "./layouts"

export interface Project {
    tourDisclaimer: boolean,
    mainColor: string,
    defaultScene: string;
    scenes: Scene[];
    // typeColors: number[][];
    layouts: Layouts;
    price: boolean;
    baseBright: number;
    filterCheck: any[];
    additionalFilterCheck: any[];
    price_condition: any;
    tutorial : {
        enabled : boolean
    }
    tooltip: {
        image_icons: boolean
    }
}
export interface Layouts {
    [key: string]: Layout;
}

export interface Layout {
    leftMenu?: LeftMenu[];
    image?: string
    [key: string]: any;
}

export interface LeftMenu {
    text: string;
    action: string;
    target: string;
    icon?: string;
}

export interface Scene {
    id: string;
    orbit?: string;
    layout: string;
    [key: string]: any;
}


export const project: Project = {
    mainColor: "345aab",
    defaultScene: "Roof",
    baseBright: 0.2,
    price: true,
    tooltip: {
        image_icons: false
    },
    // Exemple type de visite virtuelle d'un t2
    tourDisclaimer: false,
    price_condition: {
        text: "Prix ANRU TVA 5.5%",
        description: "Réservée aux personnes éligibles"
    },
    filterCheck: [
        { name: "T1", background: [255, 186, 0, 255], key: "type", value: "1", active: true, enabled: true },
        { name: "T2", background: [9, 128, 0, 255], key: "type", value: "2", active: true, enabled: true },
        { name: "T3", background: [0, 21, 181, 255], key: "type", value: "3", active: true, enabled: true },
        { name: "T4", background: [175, 0, 181, 255], key: "type", value: "4", active: true, enabled: true },
        // { name: "T5", background: [141, 0, 0, 255], key: "type", value: "5", active: true, enabled: true },
        // { name: "T6", background: [141, 65, 99, 255], key: "type", value: "6", active: true, enabled: true },
    ],
    additionalFilterCheck: [
        // { name: "Maison", background: [8, 77, 153, 255], key: "type_bien", value: "maison", active: true, enabled: true, additional: true },
        // { name: "Villa/Apt", background: [8, 77, 153, 255], key: "type_bien", value: "apt", active: true, enabled: true, additional: true },
    ],

    tutorial : {
        enabled : true,
    },

    // Same as poi
    scenes: [
        // {
        //     id: "sky",
        //     layout: "sky",
        //     tour: "sky",
        // },
        {
            id: "Roof",
            orbit: "Roof",
            layout: "base"
        },
        // {
        //     id: "F9",
        //     orbit: "F9",
        //     layout: "base"
        // },
        // {
        //     id: "F8",
        //     orbit: "F8",
        //     layout: "base"
        // },
        // {
        //     id: "F7",
        //     orbit: "F7",
        //     layout: "base"
        // },
        // {
        //     id: "F6",
        //     orbit: "F6",
        //     layout: "base"
        // },
        // {
        //     id: "F5",
        //     orbit: "F5",
        //     layout: "base"
        // },
        {
            id: "F4",
            orbit: "F4",
            layout: "base"
        },
        {
            id: "F3",
            orbit: "F3",
            layout: "base"
        },
        {
            id: "F2",
            orbit: "F2",
            layout: "base"
        },
        {
            id: "F1",
            orbit: "F1",
            layout: "base"
        },
        // {
        //     id: "Pede",
        //     orbit: "Pede",
        //     layout: "base"
        // },
        {
            id: "search",
            layout: "search"
        },
        {
            id: "favorites",
            layout: "favorites"
        },
    ],
    layouts: layouts
}

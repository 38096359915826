import React, { useEffect } from 'react';
import { useState } from "@hookstate/core"
import hive from "./index"
import { project, LeftMenu, Scene, Layouts, Layout, Project } from './project/project'


function Tutorial() {
  const { width, height, leftMenu, activeScene, mainColor, activeOrbit, tutorial, totalWidth } = useState(hive.state)
  const visible = useState(false);
  const index = useState(0);
  const steps = useState<string[]>([]);

  const close = () => {
    localStorage.setItem("tutorial", "true")
    visible.set(false);
    setTimeout(() => {
      tutorial.set(false);
    }, 400)
  }

  const cruise = (nbr: number) => {
    if (nbr >= steps.get().length) {
      close();
    } else {
      index.set(nbr);
    }
  }

  useEffect(() => {
    const scene = hive.getSceneById(hive.activeSceneId)
    const layout = project.layouts[scene.layout]
    const tmpSteps = []
    if (layout.orbit) {
      tmpSteps.push('orbit')
    }
    if (layout.searchButton) {
      tmpSteps.push('search')
    }
    if (layout.leftMenu) {
      tmpSteps.push('menu')
    }
    if (layout.orbit) {
      tmpSteps.push('magni')
    }
    if (layout.typeFilter) {
      tmpSteps.push('filter')
    }
    if (layout.compas) {
      tmpSteps.push('compas')
    }
    if (layout.searchButton) {
      tmpSteps.push('favorite')
    }
    steps.set(tmpSteps);

    setTimeout(() => {
      visible.set(true);
    }, 200)
  }, [])

  return (
    <div style={{
      background: "black",
      position: "absolute",
      width: `100vw`,
      height: `${height.get()}px`,
      top: "0px",
      left: "0px",
      zIndex: 9,
      opacity: visible.get() ? "0.7" : "0",
      transition: "opacity 0.6s",
      color: "white",
      fontSize: "1.4rem",
      fontFamily: "Acumin Pro"
    }}>

      {/* SELECTION  */}
      <div style={{
        position: "absolute",
        width: `100vw`,
        height: `${height.get() * 0.1}px`,
        top: `${(height.get() * 0.8)}px`,
        display: "flex",
        justifyContent: "center",
      }}>
        <div style={{
          width: `${width.get() * 0.4}px`,
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}>
          <div style={{
            position: "absolute",
            width: "40px",
            height: "40px",
            marginTop: `${height.get() * 0.1}px`,
            cursor: "pointer",
          }}
            onClick={() => {
              close();
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 25.697 25.697">
              <g id="croix" transform="translate(-1527.379 -266.039)">
                <path id="Path_17" data-name="Path 17" d="M-2347.246,1524.227l21.454,21.454" transform="translate(3876.746 -1256.067)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3" />
                <path id="Path_18" data-name="Path 18" d="M0,0,21.454,21.454" transform="translate(1529.5 289.614) rotate(-90)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3" />
              </g>
            </svg>
          </div>
          <div style={{
            width: "10%",
            height: "100%",
            backgroundImage: "url(/tutorial/left.svg)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "40%, 40%",
            cursor: "pointer",
            visibility: index.get() === 0 ? 'hidden' : 'initial',
          }}
            onClick={() => {
              cruise(index.get() - 1);
            }}
          >
          </div>
          <div style={{
            width: "90%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
            <div style={{
              display: "flex",
              width: "100%",
              height: "20%",
              justifyContent: "center",
            }}>
              <div style={{
                height: "100%",
                width: "50%",
                display: "flex",
                justifyContent: "center",
              }}>
                {steps.map((e, i) => <div
                  key={i}
                  style={{
                    width: "18px",
                    height: "18px",
                    borderRadius: "8px",
                    background: "white",
                    opacity: i === index.get() ? "1" : "0.5",
                    cursor: i === index.get() ? "initial" : "pointer",
                    marginLeft: "3px",
                    marginRight: "3px",
                  }}
                  onClick={() => {
                    cruise(i);
                  }}
                >
                </div>)}
              </div>
            </div>
          </div>
          <div style={{
            width: "10%",
            height: "100%",
            backgroundImage: "url(/tutorial/right.svg)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "40%, 40%",
            cursor: "pointer",
          }}
            onClick={() => {
              cruise(index.get() + 1);
            }}
          >
          </div>
        </div>
      </div>

      {/* ROTATE  */}
      {steps[index.get()].get() === "orbit" && <div style={{
        position: "absolute",
        width: `${width.get() * 0.7}px`,
        height: `${width.get() * 0.7}px`,
        left: `${(totalWidth.get() * 0.5) - ((width.get() * 0.7) * 0.5)}px`,
        top: `${(height.get() * 1) - ((width.get() * 0.7) * 0.5)}px`,
        pointerEvents: "none",
      }}>
        <div style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "20%",
        }}>
          <div style={{
            width: "20%",
            height: "100%",
            backgroundImage: "url(/tutorial/fleches.svg)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
          }}>
          </div>
        </div>
        <div style={{
          textAlign: "center",
          marginTop: "2rem",
        }}>
          Déplacez-vous autour du projet
        </div>

      </div>}

      {/* SEARCH  */}
      {steps[index.get()].get() === "search" && <div style={{
        position: "absolute",
        width: `${width.get() * 0.7}px`,
        height: `${width.get() * 0.7}px`,
        left: `${(totalWidth.get() * 0.5) - ((width.get() * 0.7) * 0.5)}px`,
        top: `${(height.get() * 0.67) - ((width.get() * 0.7) * 0.5)}px`,
        pointerEvents: "none",
      }}>
        <div style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "10%",
        }}>
          <div style={{
            width: "10%",
            height: "100%",
            backgroundImage: "url(/tutorial/fleche.svg)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}>
          </div>
        </div>
        <div style={{
          textAlign: "center",
          marginTop: "2rem",
        }}>
          Utilisez l'outil de recherche pour affiner votre séléction
        </div>

      </div>}


      {/* MENU  */}
      {steps[index.get()].get() === "menu" && <div style={{
        position: "absolute",
        width: `${width.get() * 0.9}px`,
        height: `${height.get() * 0.1}px`,
        left: `${(width.get() * 0.1)}px`,
        top: `${(height.get() * 0.2)}px`,
        pointerEvents: "none",
      }}>
        <div style={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}>
          <div style={{
            width: "11%",
            height: "100%",
            backgroundImage: "url(/tutorial/fleche-1.svg)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}>
          </div>
          <div style={{
            textAlign: "center",
            marginLeft: "1rem",
            lineHeight: `${height.get() * 0.1}px`
          }}>
            Sélectionnez l'étage à visualiser
          </div>

        </div>

      </div>}

      {/* MAGNI  */}
      {steps[index.get()].get() === "magni" && <div style={{
        position: "absolute",
        width: `${width.get() * 0.9}px`,
        height: `${height.get() * 0.1}px`,
        left: `${(width.get() * 0.1)}px`,
        top: `${(height.get() * 0.65)}px`,
        pointerEvents: "none",
      }}>
        <div style={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}>
          <div style={{
            width: "11%",
            height: "100%",
            backgroundImage: "url(/tutorial/fleche-1.svg)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}>
          </div>
          <div style={{
            textAlign: "center",
            marginLeft: "1rem",
            lineHeight: `${height.get() * 0.1}px`
          }}>
            Utilisez la loupe pour zoomer sur la maquette
          </div>

        </div>

      </div>}


      {/* FILTER  */}
      {steps[index.get()].get() === "filter" && <div style={{
        position: "absolute",
        width: `${width.get() * 0.9}px`,
        height: `${height.get() * 0.15}px`,
        left: `${(width.get() * 0.07)}px`,
        top: `${(height.get() * 0.75)}px`,
        pointerEvents: "none",
      }}>
        <div style={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}>
          <div style={{
            width: "11%",
            height: "100%",
            backgroundImage: "url(/tutorial/fleche-4.svg)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}>
          </div>
          <div style={{
            textAlign: "center",
            marginLeft: "0.5rem",
            marginTop: "-0.7rem"
          }}>
            Filtrez votre recherche par typologie de bien
          </div>

        </div>

      </div>}

      {/* COMPAS  */}
      {steps[index.get()].get() === "compas" && <div style={{
        position: "absolute",
        width: `${width.get() * 0.9}px`,
        height: `${height.get() * 0.15}px`,
        left: `calc(100vw - ${width.get()}px)`,
        top: `${(height.get() * 0.03)}px`,
        pointerEvents: "none",
      }}>
        <div style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "right"
        }}>
          <div style={{
            textAlign: "center",
            marginLeft: "0.5rem",
            marginTop: "7%",
          }}>
            Utilisez la boussole pour lire l'orientation
          </div>
          <div style={{
            width: "11%",
            height: "100%",
            backgroundImage: "url(/tutorial/fleche-2.svg)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}>
          </div>

        </div>

      </div>}

      {/* fav  */}
      {steps[index.get()].get() === "favorite" && <div style={{
        position: "absolute",
        width: `${width.get() * 0.9}px`,
        height: `${height.get() * 0.15}px`,
        left: `calc(100vw - ${width.get() * 1.02}px)`,
        top: `${(height.get() * 0.01)}px`,
        pointerEvents: "none",
      }}>
        <div style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "right"
        }}>
          <div style={{
            textAlign: "center",
            marginLeft: "0.5rem",
            marginTop: "7%",
          }}>
            Consultez vos lots favoris
          </div>
          <div style={{
            width: "11%",
            height: "100%",
            backgroundImage: "url(/tutorial/fleche-3.svg)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}>
          </div>

        </div>
      </div>}
    </div>
  );
}


export default Tutorial;
